import Avatar from "app/components/Avatar";
import Flex from "app/components/Flex";
import Icon, { Close, PaperplaneFill } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import { useRedirectNoAccess } from "hooks/Challenges";
import { useActivityCommentCreate } from "hooks/Comments";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ActivityComment } from "services/graphql";
import { useGetUserProfilePhotoUrl } from "modules/selectors";
import { useGetUserProfile } from "hooks/User";
import {
  CommentInput,
  CreateCommentContainer,
  PostIcon,
  PostLink,
} from "./styles";

const MAX_TEXTAREA_HEIGHT = 95;
const MAX_POST_LENGTH_ERROR = "Comment too long. Maximum is 500 characters.";
const SOMETHING_WENT_WRONG = "Something went wrong, Please try again.";
const ADD_COMMENT = "Add a comment...";

interface Props {
  activityId: string;
  activityType: string;
  onCreateComment: () => void;
  inputref: React.MutableRefObject<HTMLTextAreaElement>;
  replyActivityComment: ActivityComment;
  resetReplyActivity: () => void;
}

const CreateComment = ({
  activityId,
  activityType,
  onCreateComment,
  inputref,
  replyActivityComment,
  resetReplyActivity,
}: Props) => {
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState(ADD_COMMENT);
  const isPostingRef = useRef(false);
  const activityCommentCreate = useActivityCommentCreate();
  const { profileData } = useGetUserProfile();
  const photoURL = profileData?.photoURL;
  const redirectNoAccess = useRedirectNoAccess();
  const parentCommentId = replyActivityComment?.id;
  const parentCommentUserName = replyActivityComment?.commentBy?.username;

  const handleClickPost = async () => {
    if (redirectNoAccess()) {
      return;
    }

    if (isPostingRef.current || value.length === 0) {
      return;
    }
    isPostingRef.current = true;
    try {
      const success = await activityCommentCreate({
        activityId,
        activityType,
        parentCommentId,
        body: value,
      });
      isPostingRef.current = false;
      if (success) {
        onCreateComment();
        setValue("");
      } else {
        toast.error(MAX_POST_LENGTH_ERROR);
      }
    } catch (e) {
      isPostingRef.current = false;
      toast.error(SOMETHING_WENT_WRONG);
    }
  };
  const handleTextareaChange = (e: any) => {
    const textArea = e.target;
    setValue(textArea?.value);
  };

  useEffect(() => {
    setPlaceholder(
      parentCommentUserName
        ? `replying to @${parentCommentUserName}`
        : ADD_COMMENT
    );
    // eslint-disable-next-line no-param-reassign
    inputref.current.style.paddingRight = `${parentCommentId ? 22 : 10}px`;
  }, [parentCommentUserName, parentCommentId, inputref]);

  // update textarea height
  useEffect(() => {
    if (inputref.current) {
      const textArea = inputref.current;
      textArea.style.height = "0";
      const newTextAreaHeight = Math.min(
        textArea.scrollHeight,
        MAX_TEXTAREA_HEIGHT
      );
      textArea.style.height = `${newTextAreaHeight}px`;
    }
  }, [value, placeholder, inputref]);

  return (
    <CreateCommentContainer>
      <Avatar width={30} height={30} src={photoURL} alt="me" />
      <CommentInput
        value={value}
        placeholder={placeholder}
        onChange={handleTextareaChange}
        ref={inputref}
      />
      {parentCommentId && (
        <Flex position="absolute" right="55px" bottom="21px">
          <Tooltip
            overlay={`Stop replying @${parentCommentUserName}`}
            placement="left"
          >
            <Flex
              height="100%"
              width="20px"
              justifyContent="center"
              alignItems="flex-end"
              ml="-20px"
              cursor="pointer"
              onClick={resetReplyActivity}
            >
              <Icon
                width="6.73"
                height="6.73"
                as={Close}
                color="monochrome.6"
              />
            </Flex>
          </Tooltip>
        </Flex>
      )}
      <PostIcon
        as={PaperplaneFill}
        color="monochrome.6"
        onClick={handleClickPost}
      />
      <PostLink onClick={handleClickPost}>Post</PostLink>
    </CreateCommentContainer>
  );
};

export default CreateComment;
