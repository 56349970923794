import React from "react";

import { ANALYTICS_MODULE } from "constants/analytics";
import Flex from "app/components/Flex";
import env from "helpers/env";
import useToggleSaveClass from "hooks/Classes/useToggleSaveClass";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import {
  SaveIconWithTextButton,
  ShareIconWithTextButton,
  PreviewIconWithTextButton,
  MoreIconWithTextButton,
} from "app/components/Button/IconWithTextButton";
import { ClassesByInstructor } from "app/components/ClassComponents/ClassesByInstructor";
import { Class } from "services/graphql";
import { UserVideos } from "app/components/UserVideos";
import { InstructorAvatar } from "app/components/InstructorAvatar";
import { ClassTitle, HeaderLink, TracksWrapper, Wrapper } from "./styles";
import {
  SectionTitle,
  MoreContentSection,
  BrowserContainer,
  BrowserWrapper,
} from "../shared-styles";
import { TrackCopyrights } from "../TrackCopyrights";
import { TrackInfoList } from "../TrackInfoList";
import { SimilarClasses } from "../SimilarClasses";
import { ClassInfo } from "../ClassInfo";
import { DownloadMobileAppBadges } from "../DownloadMobileAppBadges";

interface Props {
  classData: Class;
  classRefId?: string;
  setIsMobileClassPreviewModalOpen(isOpen: boolean): void;
  setIsMobilePreviewPlayerModalOpen(isOpen: boolean): void;
}

export function MobileClassDetails({
  classData,
  classRefId,
  setIsMobileClassPreviewModalOpen,
  setIsMobilePreviewPlayerModalOpen,
}: Props) {
  const classTracks = classData?.tracks?.map(({ track }) => track);
  const toggleSaveClassMutation = useToggleSaveClass();

  const { isFamilyModeOn } = useFamilyModeSetting();

  return (
    <>
      <Wrapper>
        <BrowserWrapper>
          <BrowserContainer>
            <Flex
              align-items="center"
              m="12px 10px"
              gap="16px"
              justifyContent="space-between"
              flexDirection={{ _: "column", md: "row" }}
            >
              <Flex flexDirection="column" mb="12px">
                <ClassTitle>{classData.title}</ClassTitle>
                {classData.instructor?.slug && (
                  <InstructorAvatar
                    instructorSlug={classData.instructor.slug}
                  />
                )}
                <ClassInfo classData={classData} />
              </Flex>
              <DownloadMobileAppBadges />
              {!isFamilyModeOn && (
                <Flex
                  gap="24px"
                  alignItems={{ _: "center", md: "start" }}
                  justifyContent={{
                    _: "space-evenly",
                    sm: "start",
                  }}
                >
                  <PreviewIconWithTextButton
                    onClick={() => setIsMobilePreviewPlayerModalOpen(true)}
                  />
                  <ShareIconWithTextButton
                    onClick={() =>
                      navigator.share({
                        url: `${env("PUBLIC_PARKER_URL")}/class/preview/${
                          classData.id
                        }`,
                        text: `Check out this STEEZY Studio video from ${classData.instructor?.name}`,
                        title: classData.title,
                      })
                    }
                  />
                  <SaveIconWithTextButton
                    isSaved={classData.isSaved}
                    onClick={() => {
                      toggleSaveClassMutation({
                        component: "ClassDetails",
                        variables: {
                          classId: classData.id,
                          willSave: !classData.isSaved,
                          programClassRefId: classRefId,
                        },
                        classData,
                      });
                    }}
                  />
                  <MoreIconWithTextButton
                    onClick={() => setIsMobileClassPreviewModalOpen(true)}
                  />
                </Flex>
              )}
            </Flex>

            <MoreContentSection>
              <SectionTitle>
                <Flex gap="16px">
                  Connect and Upload
                  <HeaderLink to={`/class/${classData.id}/posts`}>
                    See all
                  </HeaderLink>
                </Flex>
              </SectionTitle>
              <UserVideos
                classNumber={Number(classData.id)}
                fromModule={ANALYTICS_MODULE.class_details}
              />
            </MoreContentSection>

            {classTracks && classTracks.length > 0 && (
              <TracksWrapper>
                <TrackInfoList tracks={classTracks} />
              </TracksWrapper>
            )}

            <MoreContentSection>
              <SectionTitle>Similar Classes</SectionTitle>
              <SimilarClasses classData={classData} />
            </MoreContentSection>

            {classData.instructor && (
              <ClassesByInstructor
                instructorName={classData.instructor?.name}
              />
            )}

            {classTracks && classTracks.length > 0 && (
              <TracksWrapper>
                <TrackCopyrights tracks={classTracks} />
              </TracksWrapper>
            )}
          </BrowserContainer>
        </BrowserWrapper>
      </Wrapper>
    </>
  );
}
